<template>
  <div
    class="dashboard-section"
    :class="classes"
  >
    <div class="dashboard-section__left">
      <div
        v-if="title"
        class="text-m-bold dashboard-section__title"
      >
        {{ title }}
      </div>
      <div
        v-else-if="$slots.title"
        class="text-m-bold dashboard-section__title"
      >
        <slot name="title" />
      </div>

      <slot
        v-else
        name="left"
      />
    </div>
    <div class="dashboard-section__right">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: VueTypes.string,
    noBorder: VueTypes.bool,
    noLeft: VueTypes.bool,
  },

  computed: {
    classes ({ noBorder, noLeft }) {
      return {
        'dashboard-section_no-border': noBorder,
        'dashboard-section_no-left': noLeft,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$left-width: 240px;

.dashboard-section {
  display: flex;
  border-top: 1px solid $--border-color-base;
  padding: 32px 0;

  @media (max-width: $--tablet-portrait) {
    flex-direction: column;
  }

  &_no-border {
    border-top: none;
    padding-top: 0;
  }

  &_no-left {
    display: block;

    .dashboard-section__left {
      width: auto;
      margin-bottom: 24px;

      &:empty {
        display: none !important;

      }
    }

    .dashboard-section__right {
      width: 100%;
      // margin-top: 24px;
    }
  }

  &__left {
    width: $left-width;
    flex-shrink: 0;
  }

  &__right {
    width: calc(100% - #{$left-width});

    @media (max-width: $--tablet-portrait) {
      width: 100%;
      margin-top: 24px;
    }
  }
}
</style>
