<template>
  <div class="dashboard-layout-title">
    <div class="headline-2 dashboard-layout-title__left">
      <slot>{{ title }}</slot>
    </div>
    <div class="dashboard-layout-title__right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: VueTypes.string,
  },
}
</script>

<style lang="scss" scoped>
.dashboard-layout-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  &__left {
    max-width: 100%;
  }
}
</style>
