import { render, staticRenderFns } from "./ReferralsSection.vue?vue&type=template&id=0c20dd28&scoped=true&"
import script from "./ReferralsSection.vue?vue&type=script&setup=true&lang=js&"
export * from "./ReferralsSection.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ReferralsSection.vue?vue&type=style&index=0&id=0c20dd28&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c20dd28",
  null
  
)

export default component.exports