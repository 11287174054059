<template>
  <div class="invite-link">
    <div class="text-m-bold invite-link__title">
      {{ locale.title }}
    </div>
    <div class="invite-link__link">
      {{ link }}
    </div>
    <div class="invite-link__buttons">
      <ElButton
        class="invite-link__buttons-copy"
        :type="copyLinkState ? 'success' : 'primary'"
        @click="copy"
      >
        {{ copyLinkState ? locale.copyLinkSuccess : locale.copyLink }}
      </ElButton>
      <ElButton
        class="invite-link__buttons-facebook"
        type="primary"
        square
        @click="share"
      >
        <SvgIcon
          name="facebook-icon"
          width="20px"
          height="20px"
        />
      </ElButton>
      <ElButton
        class="invite-link__buttons-twitter"
        type="primary"
        square
        @click="share"
      >
        <SvgIcon
          name="twitter-icon"
          width="22px"
          height="18px"
        />
      </ElButton>
    </div>
  </div>
</template>

<script setup>
import ElButton from 'element/ElButton'
import { useLocale, useRootLocale } from 'lib/helpers/useLocale'
import { computed, ref } from 'vue'

import { vm } from '@/main'

const copyLinkState = ref(false)
const locale = computed(() => ({
  title: useLocale('invite.link'),
  description: useLocale('invite.description'),
  copyLink: useRootLocale('button.copyLink.static'),
  copyLinkSuccess: useRootLocale('button.copyLink.success'),
}))

const token = computed(() => vm.$store.getters['streamer/referralToken'])
const link = computed(() => token.value)

const copy = () => {
  vm.$clipboard(link.value)

  copyLinkState.value = true
  setTimeout(() => {
    copyLinkState.value = false
  }, 2000)
}

const share = () => {
  if (navigator?.share) {
    try {
      navigator.share({ url: link.value })
      // eslint-disable-next-line no-console
      console.log('Удалось поделиться')
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log('Не удалось поделиться', error)
    }
  }
}
</script>

<style lang="scss" scoped>
.invite-link {
  &__title {
    margin-bottom: 8px;
  }

  &__link {
    display: inline-block;
    text-align: center;
    width: 100%;
    height: $--height-extra-large;
    color: $--color-text-regular;
    line-height: $--font-line-height-extra-large;
    background-color: $--color-primary-light-8;
    border-radius: $--border-radius-base;
    padding: 0 16px;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }

  &__buttons {
    display: flex;

    &-copy {
      width: 100%;
    }

    &-facebook,
    &-twitter {
      flex-shrink: 0;
    }

    ::v-deep {
      .el-button {
        svg {
          fill: white;
        }
      }
    }
  }
}
</style>
