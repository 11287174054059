<template>
  <ElPopover
    v-if="!$isMobile"
    class="sidebar-profile"
    placement="left-start"
    :visible-arrow="false"
    v-model="visiblePopover"
    trigger-click
  >
    <ProfileRouterLink slot="reference" />
    <ProfileLinks @click="visiblePopover = false" />
  </ElPopover>
  <div
    v-else
    class="sidebar-profile"
    @click="onProfileClick"
  >
    <ProfileRouterLink slot="reference" />
  </div>
</template>

<script>
import ElPopover from 'element/ElPopover'

import ProfileLinks from './ProfileLinks'
import ProfileRouterLink from './ProfileRouterLink'

export default {
  components: {
    ElPopover,
    ProfileRouterLink,
    ProfileLinks,
  },

  props: {
    toggleMenu: VueTypes.function,
  },

  data: () => ({
    visiblePopover: false,
  }),

  methods: {
    onProfileClick () {
      window.location.href = window.location.origin + '/profile'
      // this.$router.push({ name: 'profile' })
      this.toggleMenu()
    },
  },
}
</script>
