export type TNavigationSubItem = {
  name: string
  icon?: {
    name: string
    width: string
    height: string
  }
  params?: any
  routeName: string
  nestedRoutes?: string[]
}

export type TNavigationItem = {
  name: string
  path?: string
  inBeta?: boolean
  icon: {
    name: string
    width: string
    height: string
  }
  locale?: string[]
  params?: any
  subitems?: TNavigationSubItem[]
}

type TNavigationList = {
  streamer: TNavigationItem[]
  partner: TNavigationItem[]
}

export const navigationList: TNavigationList = {
  streamer: [
    {
      name: 'dashboard',
      path: window.location.origin + '/dashboard',
      icon: {
        name: 'home',
        width: '16px',
        height: '16px',
      },
    },
    {
      name: 'freemium',
      inBeta: true,
      icon: {
        name: 'heart',
        width: '18px',
        height: '16px',
      },
    },
    {
      name: 'campaigns',
      icon: {
        name: 'creative',
        width: '18px',
        height: '14px',
      },
      subitems: [
        {
          name: 'campaigns-livestream',
          routeName: 'campaigns-livestream',
          nestedRoutes: ['campaigns-livestream'],
        },
        {
          name: 'campaigns-preroll',
          routeName: 'campaigns-preroll',
          nestedRoutes: ['campaigns-preroll'],
        },
      ],
    },
    {
      name: 'referrals',
      path: window.location.origin + '/v3/referrals',
      icon: {
        name: 'star-referrals',
        width: '18px',
        height: '18px',
      },
    },
    {
      name: 'wallet',
      path: window.location.origin + '/wallet',
      icon: {
        name: 'wallet',
        width: '17px',
        height: '17px',
      },
    },
    // {
    //   name: 'extension',
    //   icon: {
    //     name: 'extension',
    //     width: '18px',
    //     height: '18px',
    //   },
    // },
    {
      name: 'widget',
      path: window.location.origin + '/widget',
      icon: {
        name: 'gear',
        width: '18px',
        height: '18px',
      },
    },
  ],
  partner: [
    {
      name: 'campaigns',
      icon: {
        name: 'creative',
        width: '18px',
        height: '15px',
      },
      subitems: [
        {
          name: 'brand-awareness',
          routeName: 'brand-awareness-campaigns',
          nestedRoutes: ['brand-awareness-campaigns', 'brand-awareness-groups', 'brand-awareness-creatives'],
        },
        {
          name: 'performance',
          routeName: 'performance-campaigns',
          nestedRoutes: ['performance-campaigns', 'performance-groups'],
        },
        {
          name: 'preroll',
          routeName: 'preroll-campaigns',
          nestedRoutes: ['preroll-campaigns', 'preroll-groups'],
        },
      ],
    },
    {
      name: 'advertisers',
      icon: {
        name: 'wallet',
        width: '15px',
        height: '15px',
      },
    },
    {
      name: 'creators',
      icon: {
        name: 'star-referrals',
        width: '15px',
        height: '15px',
      },
    },
  ],
}
