import 'element/css/switch.scss'

import Switch from './component'

/* istanbul ignore next */
Switch.install = function (Vue) {
  Vue.component(Switch.name, Switch)
}

export default Switch
