<template>
  <div class="dashboard-sidebar-socials">
    <div class="text-s-regular dashboard-sidebar-socials__title">
      {{ locale.title }}
    </div>

    <div class="dashboard-sidebar-socials__items">
      <a
        v-for="item in items"
        :key="item.name"
        :href="item.link"
        class="dashboard-sidebar-socials__item"
        target="_blank"
      >
        <SvgIcon
          :name="`${item.name}-icon`"
          :width="item.width || '16px'"
          :height="item.height || '16px'"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    locale: ({ $rootLocale }) => ({
      title: $rootLocale('sidebar.socials.title'),
    }),
    items ({ $i18n }) {
      if ($i18n.locale === 'ru') {
        return [
          {
            link: 'https://www.facebook.com/TryUplify',
            name: 'facebook',
            width: '22px',
            height: '22px',
          },
          {
            link: 'https://www.youtube.com/channel/UC6N_7PjAppYHZoDjpQScOAQ/featured',
            name: 'youtube',
            width: '26px',
            height: '20px',
          },
          {
            link: 'https://vk.com/uplify',
            name: 'vk',
            width: '22px',
            height: '22px',
          },
        ]
      }
      else {
        return [
          {
            link: 'https://twitter.com/TryUplify',
            name: 'twitter',
            width: '22px',
            height: '22px',
          },
          {
            link: 'https://www.facebook.com/TryUplify',
            name: 'facebook',
            width: '22px',
            height: '22px',
          },
        ]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-sidebar-socials {
  &__title {
    color: $--color-text-regular;
    margin-bottom: 10px;
  }

  &__items {
    display: flex;
    align-items: center;
  }

  &__item {
    display: inline-flex;
    margin-right: 18px;

    ::v-deep svg{
      fill: $--color-text-regular;
    }

    &:hover {
      ::v-deep svg {
        fill: $--link-color;
      }
    }
  }
}
</style>
