<template>
  <div
    class="profile-router-link"
    :class="{'profile-router-link_active': $route.name === 'profile'}"
  >
    <div class="profile-router-link__inner">
      <div class="profile-router-link__inner-left">
        <div
          v-if="role === 'streamer'"
          class="profile-router-link__userpic"
          :style="`background-image: url(${avatar})`"
        />
        <div
          v-else
          class="text-m-bold profile-router-link__initials"
        >
          {{ initials }}
        </div>
      </div>
      <div class="profile-router-link__inner-right">
        <div class="text-s-regular profile-router-link__nickname">
          {{ name }}
        </div>
        <div class="text-caption-caps">
          {{ role }}
        </div>
      </div>

      <SvgIcon
        name="chevron-right"
        width="16"
        height="16"
      />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    role: ({ $store }) => $store.getters['auth/role'],
    name: ({ $store }) => $store.getters['auth/name'],
    user: ({ $store }) => $store.getters['auth/user'],
    widget: ({ $store }) => $store.getters['widgetSettings/widget'],
    twitch: ({ $store }) => $store.state.auth.twitch,
    youtube: ({ $store }) => $store.state.auth.youtube,
    trovo: ({ $store }) => $store.state.auth.trovo,

    avatar () {
      return this[this.widget.platform]?.avatar
    },

    initials ({ name }) {
      const names = name.split(' ')

      let initials = names[0].charAt(0).toUpperCase()

      if (names[1]) {
        initials += names[1].charAt(0).toUpperCase()
      }

      return initials
    },
  },
}
</script>

<style lang="scss" scoped>
$avatar-width: 40px;
$avatar-height: $avatar-width;

.profile-router-link {
  display: block;
  width: calc(100% + 24px);
  background-color: white;
  border-radius: $--border-radius-base;
  padding: 6px 12px;
  margin: -6px -12px;
  transition: $--all-transition;
  cursor: pointer;

  $root: &;

  &:hover {
    background-color: $--color-primary-light-8;
  }

  &_active {
    background-color: $--color-primary-light-8;

    #{$root}__nickname {
      color: $--color-primary;
    }

    #{$root}__userpic {
      border-color: $--color-primary;
    }
  }

  &__inner {
    display: flex;
    align-items: center;

    &-left {
      flex-shrink: 0;
      width: $avatar-width;
      height: $avatar-height;
    }

    &-right {
      width: calc(100% - 40px - 16px);
      padding-left: 8px;
    }
  }

  &__userpic {
    width: $avatar-width;
    height: $avatar-height;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $--border-radius-circle;
    border: $--border-base;
    transition: $--all-transition;
  }

  &__initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $avatar-width;
    height: $avatar-height;
    border-radius: $--border-radius-circle;
    background-color: $--color-primary;
    color: white;
    transition: $--all-transition;
  }

  &__nickname {
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 2px;
    transition: $--all-transition;
  }

  ::v-deep {
    svg {
      flex-shrink: 0;
      fill: #666666;
    }
  }
}
</style>
