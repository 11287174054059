var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.item.path)?_c('router-link',{staticClass:"dashboard-sidebar-nav__item-link",class:{
    'dashboard-sidebar-nav__item-link-active': _setup.isActive(_vm.$route.name, _vm.item),
    'dashboard-sidebar-nav__item-link-has-actives': _setup.hasActiveSubitems(_vm.$route.name, _vm.item),
  },attrs:{"to":{
    name: _vm.item.subitems?.length ? _vm.item.subitems[0].name : _vm.item.name,
    params: _vm.item.params && _vm.item.params
  },"exact":""}},[_vm._t("default")],2):_c('a',{staticClass:"dashboard-sidebar-nav__item-link",class:{
    'dashboard-sidebar-nav__item-link-active': _setup.isActive(_vm.$route.name, _vm.item),
    'dashboard-sidebar-nav__item-link-has-actives': _setup.hasActiveSubitems(_vm.$route.name, _vm.item),
  },attrs:{"href":_vm.item.path,"exact":""}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }