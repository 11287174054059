<template>
  <div
    class="dashboard-sidebar"
    :class="{'dashboard-sidebar_menu-active': menuActive}"
  >
    <div class="dashboard-sidebar__top">
      <DashboardSidebarLogo />
      <button
        class="dashboard-sidebar__menu-btn"
        @click="toggleMenu"
      >
        <SvgIcon
          :name="!menuActive ? 'menu-icon' :'close-menu-icon'"
          width="24px"
          height="24px"
        />
      </button>
    </div>

    <div class="dashboard-sidebar__content">
      <DashboardSidebarNav :toggle-menu="toggleMenu" />

      <div class="dashboard-sidebar__bottom">
        <DashboardRequestDemo v-if="$isPartner" />
        <DashboardSidebarProfile :toggle-menu="toggleMenu" />
        <div class="dashboard-sidebar__separator dashboard-sidebar__separator_profile" />

        <DashboardSidebarLinks />
        <div class="dashboard-sidebar__separator" />

        <div v-show="!$isMobile">
          <DashboardSidebarSocials />
          <div class="dashboard-sidebar__separator" />
        </div>

        <div class="dashboard-sidebar__footer">
          <LocaleSwitcher />
          <DashboardLogoutButton v-show="$isMobile" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from 'components/LocaleSwitcher'

import DashboardLogoutButton from './components/DashboardLogoutButton'
import DashboardRequestDemo from './components/DashboardRequestDemo'
import DashboardSidebarLinks from './components/DashboardSidebarLinks'
import DashboardSidebarLogo from './components/DashboardSidebarLogo'
import DashboardSidebarNav from './components/DashboardSidebarNav'
import DashboardSidebarProfile from './components/DashboardSidebarProfile'
import DashboardSidebarSocials from './components/DashboardSidebarSocials'

export default {
  components: {
    LocaleSwitcher,
    DashboardSidebarLogo,
    DashboardSidebarNav,
    DashboardSidebarProfile,
    DashboardRequestDemo,
    DashboardSidebarLinks,
    DashboardSidebarSocials,
    DashboardLogoutButton,
  },

  data: function () {
    return {
      menuActive: false,
    }
  },

  methods: {
    toggleMenu () {
      if (!this.$isMobile) return

      this.menuActive = !this.menuActive
      document.querySelector('html').style.overflow = this.menuActive ? 'hidden' : 'auto'
      document.querySelector('body').style.overflow = this.menuActive ? 'hidden' : 'auto'
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: $--sidebar-width;
  height: 100vh;
  background: $--sidebar-background-color;
  box-shadow: $--sidebar-shadow;
  padding: 34px 24px 28px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;

  @media (max-width: $--tablet-portrait) {
    position: relative;
    z-index: 15;
    width: 100%;
    height: auto;
    padding: 20px;
    box-shadow: none;

    &_menu-active {
      height: 100vh;
      overflow: auto;

      .dashboard-sidebar__content {
        display: flex;
      }
    }
  }

  &__top {
    @media (max-width: $--tablet-portrait) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 15;
    }
  }

  &-logo {
    @media (max-width: $--tablet-portrait) {
      margin-bottom: 0;
    }
  }

  &__menu-btn {
    display: none;

    @media (max-width: $--tablet-portrait) {
      display: inline-block;
      width: 24px;
      height: 24px;
      border: none;
      background-color: transparent;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $--tablet-portrait) {
      display: none;
      padding-top: 36px;
    }
  }

  &__separator {
    border-top: 1px solid $--border-color-base;
    margin: 16px 0;

    &_profile {
      margin-top: 12px;
    }
  }

  &__bottom {
    margin-top: auto;
    @media (max-width: $--tablet-portrait) {
      padding-top: 80px;
    }

    &::v-deep {
      .locale-switcher {
        margin-top: -14px;
        margin-left: -7px;
        margin-bottom: -10px;
      }
    }
  }

  &__footer {
    @media (max-width: $--tablet-portrait) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
  }
  }
}
</style>
