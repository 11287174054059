<template>
  <div
    class="dashboard-sidebar-logout"
    @click="logout"
  >
    <SvgIcon
      class="dashboard-sidebar-logout__icon"
      name="logout"
      width="16px"
      height="16px"
    />
    <span class="text-s-regular dashboard-sidebar-logout__label">
      {{ $t('sidebar.navigation.profile.logout') }}
    </span>
  </div>
</template>

<script>
import { removeToken } from 'lib/helpers/authToken'

export default {
  methods: {

    logout () {
      removeToken()
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-sidebar-logout {
  $root: &;

  display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 20px;
    margin-bottom: 16px;

    @media (max-width: $--tablet-portrait) {
      margin-bottom: 0;
    }

    &-link {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:hover {
        #{$root}__icon {
          fill: $--link-color;
        }

        #{$root}__label {
          color: $--link-color;
        }
      }
    }

    &__label {
      color: $--color-text-regular;
      transition: $--all-transition;
    }

    &__icon {
      fill: $--color-text-regular;
      transition: $--all-transition;
      margin-right: 7px;
    }
}
</style>
